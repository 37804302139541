
import {ANNOTATION_VIDEO_DISPLAY,ANNOTATION_PARENT} from '../../globalConstants/GlobalConstants';

const ANNOTATION_CONTACT_TEXT = 'geraldine@kahn-gallery.com';

const AnnotationsDB = [
    {
        paintingId: 'W01P01',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Rayures et Leopard #1</h5><h6>2019</h6>',
        emailSubjectText: 'Rayures et Leopard #1 2019',
        bodyText: `Oil on canvas <br> 61 x 46 x 2 cm / 24 x 17 x 0.78 in<br> Original artwork<br> £1,300 / $1,700 / 1,200€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/rayuresetleopard1',
        isInSitu: 'Y',
        images: ['W01P01_IS01.jpeg'],
        artistInspirationUrl:'https://www.youtube.com/watch?v=I6XrZmEY8rQ',
    },

    {
        paintingId: 'W01P02',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Rayures et Leopard #5</h5><h6>2019</h6>',
        emailSubjectText: 'Rayures et Leopard #5 2019',
        bodyText: 'Oil on canvas <br> 61 x 46 x 2  cm/ 24 x 17 x 0.8 in <br> Original artwork <br> £1,300 / $ 1,700 / 1,200€',
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/rayures-et-leopard-5',
        displayHeight:80,
        isInSitu: 'Y',
        images: ['W01P02_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=I6XrZmEY8rQ',
    },

    {
        paintingId: 'W01P03',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Rayures et Leopard #4</h5><h6>2019</h6>',
        emailSubjectText: 'Rayures et Leopard #4 2019',
        bodyText: `Oil on canvas <br> 61 x 46 x 2 cm / 24 x 17 x 0.78 in
<br> Original artwork
<br> SOLD`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        isSold:'Y',
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/rayures-et-leopard-4',
        isInSitu: 'Y',
        images: ['W01P03_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=I6XrZmEY8rQ',
    },

    {
        paintingId: 'W01P04',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Rayures et Leopard #3</h5><h6>2019</h6>',
        emailSubjectText: 'Rayures et Leopard #3 2019',
        bodyText: `Oil on canvas <br> 61 x 46 x 2 cm / 24 x 17 x 0.78 in
<br> Original artwork
<br> £1,300 / $ 1,700 / 1,200€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/rayures-et-leopard-3',
        isInSitu: 'Y',
        images: ['W01P04_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=I6XrZmEY8rQ',
    },

    {
        paintingId: 'W01P05',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Rayures et Leopard #2</h5><h6>2019</h6>',
        emailSubjectText: 'Rayures et Leopard #2 2019',
        bodyText: `Oil on canvas <br> 61 x 46 x 2 cm / 24 x 17 x 0.78 inches
<br> Original artwork
<br> £1,300 / $ 1,700 / 1,200€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/rayures-et-leopard-2',
        isInSitu: 'Y',
        images: ['W01P05_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=I6XrZmEY8rQ',
    },

    {
        paintingId: 'W02P01',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Chaleur Tropicale</h5><h6>2019</h6>',
        emailSubjectText: 'Chaleur Tropicale 2019',
        bodyText: `Acrylic on canvas <br> 116 x 81 x 2 cm / 45 x 32 x 0.8 inches
<br> Original artwork <br> SOLD`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        isSold:'Y',
        isInSitu: 'Y',
        images: ['W02P01_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=mp-NIC6X0GQ',
    },

    {
        paintingId: 'W02P02',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Foxy Lady</h5><h6>2017</h6>',
        emailSubjectText: 'Foxy Lady 2017',
        bodyText: `Oil on canvas <br> 116 x 81 x 2 cm / 45 x 32 x 0.8 in
<br> Original artwork <br> £3,100 / $4,000 / 3,100€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/foxy-lady',
        displayHeight:85,
        isInSitu: 'Y',
        images: ['W02P02_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=_PVjcIO4MT4',
    },

    {
        paintingId: 'W02P03',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Le Mariage Collectif</h5><h6>2018</h6>',
        emailSubjectText: 'Le Mariage Collectif 2018',
        bodyText: `Oil on canvas <br> 97 x 130 x 2 cm / 38.1 x 51.1 x 0.8 in
<br> Original artwork <br> SOLD`,
        isSold:'Y',
        isInSitu: 'Y',
        images: ['W02P03_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=uw2rWae2ntw',
    },

    {
        paintingId: 'W02P04',
        videoId: 'W02P04',
        displayType: ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO,
        videoURL: 'W02P04.mp4',
        headerText: '<h5>La Marchande de Poissons</h5><h6>2019</h6>',
        emailSubjectText: 'La Marchande de Poissons 2019',
        bodyText: `Oil on canvas <br> 116 x 73 x 2 cm / 45.6 x 28.7 x 0.8 in
<br> Original artwork <br> £3,200 / USD / €2,900`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/la-marchande-de-poisson',
        isInSitu: 'N',
        images: [],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=kdr3G9oEudQ',
    },

    {
        paintingId: 'W03P01',
        videoId: 'W03P01',
        displayType: ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO,
        videoURL: 'W03P01.mp4',
        headerText: '<h5>Nouvelle Vague</h5><h6>2019</h6>',
        emailSubjectText: 'Nouvelle Vague 2019',
        bodyText: `Oil on surfboard <br> 184 x 52 x 4 cm / 70.8 x 19.7 x 1.9 in
<br> Original artwork <br> £2,500 / $3,200 / 2,800€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        isInSitu: 'Y',
        images: ['W03P01_IS01.jpeg','W03P01_IS02.jpg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=NwVA5zYfNWw',
    },

    {
        paintingId: 'W03P02',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Live at BDA New Orleans</h5><h6>2018</h6>',
        emailSubjectText: 'Live at BDA New Orleans 2018',
        bodyText: `Oil on canvas <br> 116 x 81 x 2 cm / 45 x 32 x 0.8 inches
<br> Original artwork <br> £3,100 / $4,000 / 3,100€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/liveatbda',
        displayHeight:75,
        isInSitu: 'Y',
        images: ['W03P02_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=psaHLi7-DIs',
    },

    {
        paintingId: 'W03P03',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Pan-Pan</h5><h6>2018</h6>',
        emailSubjectText: 'Pan-Pan 2018',
        bodyText: `Oil on canvas <br> 130 x 97 x 2 cm /  51.1 x 38.8 x 0.8 in
<br> Original artwork <br> SOLD`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        isSold:'Y',
        isInSitu: 'Y',
        images: ['W03P03_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=ltP7L16A8Hs',
    },

    {
        paintingId: 'W03P04',
        videoId: 'W03P04',
        displayType: ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO,
        videoURL: 'W03P04.mp4',
        headerText: '<h5>Une Bouteille à ta Mère</h5><h6>2020</h6>',
        emailSubjectText: 'Une Bouteille à ta Mère 2020',
        bodyText: `Oil on canvas <br> 130 x 200 x 2.5 cm / 51.1 x 78.7 x 0.8 in
<br> Original artwork <br> £6,600 / $8,400 / 7,500€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/unebouteilleatamere',
        W02P03:85,
        isInSitu: 'Y',
        images: ['W03P04_IS01.jpeg','W03P04_IS02.jpg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=O27i-1Th--o',
    },

    {
        paintingId: 'W03P05',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Plan Machiavélique</h5><h6>2020</h6>',
        emailSubjectText: 'Plan Machiavélique 2020',
        bodyText: `Oil on canvas <br> 120 x 80 x 2 cm / 47.2 x 31.5 x 0.8 in
<br> Original Artwork <br> SOLD`,
        isSold:'Y',
        isInSitu: 'Y',
        images: ['W03P05_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=miCF3TSXDiw',
    },

    {
        paintingId: 'W03P06',
        videoId: 'W03P06',
        displayType: ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO,
        videoURL: 'W03P06.mp4',
        headerText: '<h5>Collier de Crevettes</h5><h6>2019</h6>',
        emailSubjectText: 'Collier de Crevettes 2019',
        bodyText: `Oil on canvas <br> 81 x 54 x 2 cm / 31.8 x 21.2 x 0.8 in
<br> Original artwork <br> £2,200 / USD / 2,200€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/collierdecrevettes',
        isInSitu: 'Y',
        images: ['W03P06_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=ltP7L16A8Hs',
    },

    {
        paintingId: 'W04P01',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Diva La Kour</h5><h6>2019</h6>',
        emailSubjectText: 'Diva La Kour 2019',
        bodyText: `Oil on canvas <br> 90 x 60 x 2 cm / 35.4 x 23.6 x 0.8 in
<br> Original artwork <br> SOLD`,
        isSold:'Y',
        isInSitu: 'Y',
        images: ['W04P01_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=01U_g1KFw4k',
    },

    {
        paintingId: 'W04P02',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Nati</h5><h6>2020</h6>',
        emailSubjectText: 'Nati 2020',
        bodyText: `Oil on canvas <br> 90 x 120 x 2.5 cm / 35.4 x 47.2 x 0.8 in
<br> Original artwork <br> £3,100 / $4,000 / 3,100€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/nati',
        isInSitu: 'Y',
        images: ['W04P02_IS01.jpeg','W04P02_IS02.jpg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=ku2XHROcJNw',
    },

    {
        paintingId: 'W04P03',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Poulpeuse Geisha</h5><h6>2019</h6>',
        emailSubjectText: 'Poulpeuse Geisha 2019',
        bodyText: `Oil on canvas <br> 130 x 80 x 2.5 cm / 47.2 x 31.5 x 0.8 in
<br> Original artwork <br> £3,400 / $3,800 / 4,300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/poulpeusegeisha',
        displayHeight:80,
        isInSitu: 'Y',
        images: ['W04P03_IS01.jpeg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=y_MW38SoxxI',
    },

    {
        paintingId: 'W04P04',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Brèdes Choucou</h5><h6>2020</h6>',
        emailSubjectText: 'Brèdes Choucou 2020',
        bodyText: `Oil on canvas <br> Original artwork
<br> 120 x 80 x 2.5 cm / 47.2 x 31.5 x 0.8 in <br> £3,100 / $4,000 / 3,100€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/bredes-chouchou',
        displayHeight:80,
        isInSitu: 'Y',
        images: ['W04P04_IS01.jpeg','W04P04_IS02.jpg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=lULXXw1ePag&feature=youtu.be',
    },

    {
        paintingId: 'W04P05',
        videoId: null,
        videoURL: null,
        headerText: '<h5>Après-midi confi</h5><h6>2020</h6>',
        emailSubjectText: 'Après-midi confi 2020',
        bodyText: `Oil on canvas <br>120 x 120 x 2.5cm / 47.2 x 47.2 x 0.8 in
<br> £3,400 / $3,800 / 4,300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/products-aureliequentin/apres-midi-confi',
        isInSitu: 'Y',
        images: ['W04P05_IS01.jpeg','W04P05_IS02.jpg'],
        artistInspirationUrl: 'https://www.youtube.com/watch?v=muC8-VDPrIc&feature=youtu.be',
    },
    {
        paintingId: 'W03T01',
        annotationScale: {x:.1,y:.1,z:1},
        annotationPos:{x:5.9,y:2,z:12.086},
        annotationRotation: {x:0,y:0,z:0},
        spriteImageURL:`/assets/sprites/followIcon.svg`,
        imageURL:`/images/SocialMediaAnnotation.png`,
        videoId: null,
        videoURL: null,
        headerText: 'FOLLOW FOR UPDATES',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
89 x 146 cm / 35 x 57 inches
Original artwork
£8200 / $10000 / 9300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/saintdenis',
        displayHeight:85,
        annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        socialMedia:'Y',
    },
    {
        paintingId: 'W02T01',
        annotationScale: {x:.1,y:.1,z:1},
        annotationPos:{x:-8.6,y:1.8,z:3},
        annotationRotation: {x:0,y:0,z:0},
        annotationImageURL:null,
        spriteImageURL:`/assets/sprites/followIcon.svg`,
        imageURL:`/images/SocialMediaAnnotation.png`,
        videoId: null,
        videoURL: null,
        headerText:  'FOLLOW FOR UPDATES',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
89 x 146 cm / 35 x 57 inches
Original artwork
£8200 / $10000 / 9300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/saintdenis',
        displayHeight:85,
        annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        socialMedia:'Y',
    },
];



export {AnnotationsDB, ANNOTATION_CONTACT_TEXT};
