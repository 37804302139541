import {AnnotationsDB, ANNOTATION_CONTACT_TEXT} from './AnnotationDB';
import {ANNOTATION_VIDEO_DISPLAY, ANNOTATION_PARENT} from '../../globalConstants/GlobalConstants';
import * as THREE from 'three';

class AnnotationDataController {

    static cached_AnnotationDataById = {};

    constructor() {
    }

    static getAnnotationsWithNoParent() {
        const noParentRecords = [];
        const records = AnnotationsDB.filter(rec => rec.annotationParent === ANNOTATION_PARENT.PARENT_NONE);
        if (records) {
            records.forEach(item => {
                noParentRecords.push(AnnotationDataController.populateRecord(item));
            })
        }

        return noParentRecords;
    }

    //todo: need to cache these records in object
    static getAnnotationDataById(annotationId) {

        let records = AnnotationsDB.filter(rec => rec.paintingId === annotationId)
        if (records && records.length > 0) {
            const rec = AnnotationDataController.populateRecord(records[0]);
            if (annotationId in AnnotationDataController.cached_AnnotationDataById) {

                return  AnnotationDataController.cached_AnnotationDataById[annotationId];

            }
            else {

                AnnotationDataController.cached_AnnotationDataById[annotationId] = rec;
                return rec;
            }
        }

        return null;
    }

    static populateRecord(record) {

        if (record) {

            const isSold = record.isSold || 'N';
            let videoURL = null;
            let isAnnotationVideo = false;
            let isStandaloneVideo = false;
            let isAnnotation = false;
            let displayType = record.displayType || ANNOTATION_VIDEO_DISPLAY.ANNOTATION_TEXT;
            isAnnotationVideo = (displayType & ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO);
            isAnnotation = (displayType & ANNOTATION_VIDEO_DISPLAY.ANNOTATION_TEXT) || (displayType & ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO);
            isStandaloneVideo = (displayType & ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE);
            const annotationScale = record.annotationScale ? new THREE.Vector3(record.annotationScale.x, record.annotationScale.y, record.annotationScale.z) : null;
            const annotationPos = record.annotationPos ? new THREE.Vector3(record.annotationPos.x, record.annotationPos.y, record.annotationPos.z) : null;
            const annotationRotation = record.annotationRotation ? new THREE.Vector3(record.annotationRotation.x, record.annotationRotation.y, record.annotationRotation.z) : null;
            const imageURL = record.imageURL || `/paintings/${record.paintingId}.jpg`;

            let ContactURL;
            let EnquireEmail;
            let shareEmail;
            let  emailSubjectText = record.emailSubjectText || record.headerText;
            if (isSold === 'N') {
                ContactURL = record.ContactURL;
                EnquireEmail = `mailto:${record.EnquireEmail}?subject=${emailSubjectText} by Aurélie Quentin&body=${record.ContactURL}`;
                shareEmail = `mailto:?subject= ${emailSubjectText} by Aurélie Quentin at Kahn Gallery&body=${emailSubjectText} by Aurélie Quentin at Kahn Gallery` + escape("\n") + encodeURIComponent(record.ContactURL);
            }
            else {
                ContactURL = null;
                EnquireEmail = `mailto:${record.EnquireEmail}?subject=${emailSubjectText} by Aurélie Quentin`;
                shareEmail = `mailto:?subject= ${emailSubjectText} by Aurélie Quentin at Kahn Gallery&body=${emailSubjectText} by Aurélie Quentin at Kahn Gallery`;
            }

            console.log("[AnnotationDataControl], isSold", isSold)

            return ({
                moreText: record.moreText,
                headerText: record.headerText,
                bodyText: record.bodyText,
                paintingId: record.paintingId,
                imageUrl: process.env.PUBLIC_URL + imageURL,
                ContactURL: ContactURL,
                isAnnotationVideo: isAnnotationVideo,
                isAnnotation: isAnnotation,
                isStandaloneVideo: isStandaloneVideo,
                displayMesh: record.displayMesh || 'Y',
                displayHeight: record.displayHeight || 100,
                videoURL: process.env.PUBLIC_URL + `/videos/${record.videoURL}`,
                EnquireEmail:EnquireEmail,
                shareEmail:shareEmail,
                annotationScale: annotationScale,
                annotationPos: annotationPos,
                annotationParent: record.annotationParent || ANNOTATION_PARENT.PARENT_MESH,
                isSold: isSold,
                socialMedia: record.socialMedia || 'N',
                spriteImageURL: record.spriteImageURL,
                isInSitu: record.isInSitu || 'N',
                images: record.images || [],
                artistInspirationUrl: record.artistInspirationUrl,
                emailSubjectText: emailSubjectText,
            });
        }

        return null;
    }
}

export default AnnotationDataController;
