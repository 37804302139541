import * as THREE from "three";
import {CSS3DObject} from "three/examples/jsm/renderers/CSS3DRenderer";

class VideoDisplay {
    videoScreen;
    videoElm;
    _isPlaying = false;
    videoButton;
    videoScreenInit = false;

    constructor(mediaRecord, mesh, scene, css3dScene, videosGroup) {

        this.mediaRecord = mediaRecord;
        this.mesh = mesh;
        this._mediaId = mediaRecord.paintingId;
        this.scene = scene;
        this.css3dScene = css3dScene;

        const spriteMapPlay = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/assets/sprites/videoPlayButton.svg');
        const spriteMapPause = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/assets/sprites/videoPauseButton.svg');

        this.spriteMaterialPlay = new THREE.SpriteMaterial({map: spriteMapPlay, color: 0xffffff, fog: true});
        this.spriteMaterialPause = new THREE.SpriteMaterial({map: spriteMapPause, color: 0xffffff, fog: true});

        this.videosGroup = videosGroup;

        this.calcPosition();
    }

    calcPosition() {
        const boundingBox = this.mesh.geometry.boundingBox;
        // const center = boundingBox.getCenter(new THREE.Vector3())
        const boxSize = boundingBox.getSize(new THREE.Vector3());
        const videoRotation = this.mesh.quaternion.clone();
        let worldPos = new THREE.Vector3();
        let worldPosVideoButton = new THREE.Vector3();


        //console.log("videoDisplay=", worldPos, center, boxSize, videoRotation, boundingBox);

        this.videoElm = document.getElementById(this.mediaRecord.standaloneVideoElementId);
        const texture = new THREE.VideoTexture(this.videoElm);
        const parameters = {color: 0xffffff, map: texture, side: THREE.DoubleSide};

        const geometry = new THREE.PlaneBufferGeometry(boxSize.x, boxSize.y);
        // const material = new THREE.MeshLambertMaterial( parameters );
        // const material = new THREE.MeshBasicMaterial( {color: 0xffff00, side: THREE.DoubleSide});
        const material = new THREE.MeshBasicMaterial(parameters);
        this.videoScreen = new THREE.Mesh(geometry, material);
       // this.videoScreen.applyQuaternion(videoRotation);
        const offsetPos = new THREE.Vector3(0, 0, boxSize.z + 0.03);
        const offsetPosVideoButton = new THREE.Vector3(0, 0, boxSize.z + 0.1);
        this.mesh.add(this.videoScreen);

        this.videoScreen.position.copy(offsetPosVideoButton);
        this.videoScreen.getWorldPosition(worldPosVideoButton);
        this.videoScreen.position.copy(offsetPos);
        this.videoScreen.getWorldPosition(worldPos);
        this.videoScreen.visible = false;

        // this.scene.add(videoButton);
        const videoButtonPos = worldPosVideoButton.clone();

       /* const video = this.createCss3dVideoPlayer(boxSize.x * 1000, boxSize.y * 1000);
        video.position.copy(worldPos);
        video.applyQuaternion(videoRotation);
        this.css3dScene.add(video);*/

        this.videoButton = this.createVideoButton(videoButtonPos, boxSize);
        this.videosGroup.add(this.videoButton);

        if (this.mediaRecord.displayMesh === 'N') {

            this.mesh.remove(this.videoScreen);
            this.videoScreen.position.copy(worldPos);
            this.videoScreen.applyQuaternion(videoRotation);
            this.scene.add(this.videoScreen);

        }
    }

    initVideoScreen = () => {
        if (this.videoScreenInit) return;
        this.videoScreen.visible = true;
        this.videoScreenInit = true;
    }


    playVideo = () => {
        this.initVideoScreen();
        this.videoElm.play();
        this._isPlaying = true;
        this.videoButton.material = this.spriteMaterialPause;
    }

    PauseVideo = () => {
        this.videoElm.pause();
        this._isPlaying = false;
        this.videoButton.material = this.spriteMaterialPlay;
    }


    get isPlaying() {
        return this._isPlaying;
    }


    createVideoButton = (videoButtonPos, boxSize) => {

        const sprite = new THREE.Sprite(this.spriteMaterialPlay);
        sprite.scale.set(.2, .2, 1);
        sprite.userData = this.mediaRecord.paintingId;
        videoButtonPos.setY(videoButtonPos.y - boxSize.y / 2 - .2);
        sprite.position.copy(videoButtonPos);
        return sprite;
    }

    createCss3dVideoPlayer(width, height) {
        const div = document.createElement('div');
        div.style.width = `${width}px`;
        div.style.height = `${height}px`;
        div.style.backgroundColor = '#000';
        const video = document.createElement('iframe');
        video.style.width = `${width}px`;
        video.style.height = `${height}px`;
        video.style.border = '0px';
        video.src = ['https://www.youtube.com/embed/', 'SJOz3qjfQXU', '?rel=0'].join('');

        /* const video = document.createElement('video');
         video.style.width = `${width}px`;
         video.style.height = `${height}px`;
         video.style.border = '0px';


         const sourceMP4 = document.createElement("source");
         sourceMP4.type = "video/mp4";
         sourceMP4.src = this.mediaRecord.videoURL;
         video.appendChild(sourceMP4);*/


        div.appendChild(video);

        const object = new CSS3DObject(div);
        object.scale.set(.001, .001, 1);

        return object;

    }


    get mediaId() {
        return this._mediaId;
    }
}

export default VideoDisplay;
